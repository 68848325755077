import React from 'react'

let Guest = React.createContext(null);
let SetGuest = React.createContext(() => {});

export {
    Guest,
    SetGuest
}

export default Guest;