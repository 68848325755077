import React, { Component } from 'react';
import { Row, Col, Typography, Collapse, Button, Popconfirm, Modal, Spin, PageHeader, Layout, Space, message, Card, List, Tag } from 'antd'
import axios from 'axios';
import moment from 'moment'

//componentes
import Logged from "../../../Hooks/Logged";
import { AiOutlineEye } from 'react-icons/ai';
import { FilterOutlined } from '@ant-design/icons';
import FiltrosDrawer from '../../Widgets/DrawerFiltros/DrawerFiltros';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail, IconEye } from '../../Widgets/Iconos';
import { EstatusVenta } from "../../Utils";

//modal
import ModalVentas from './ModalVentas'
import ModalVentasDetalle from './ModalDetalle'
import Paragraph from 'antd/lib/typography/Paragraph';


const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography

/**
 *
 *
 * @export
 * @class Ventas
 * @extends {Component}
 * @description Vista donde se listan todos las ventas
 */
export default class Ventas extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            ventas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
                filters: []

            },
            venta_id: null
        }
    }

    componentDidMount() {

        this.getVentas()
    }


    /**
    *
    * @memberof Ventas
    * @method getVentas
    * @description Obtiene la informacion de las ventas
    */
    getVentas = async ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.props.search,
        filters = this.state.ventas.filters
    } = this.state.ventas) => {

        this.setState({ loading: true, ventas: { ...this.state.ventas, page, limit, filters } })
        console.log("page", page, "limit", limit, "search", search, "filters", filters)
        axios.get('/ventas', {
            params: { limit, page, search, filters }
        })
            .then(({ data }) => {
                this.setState({ ventas: {...this.state.ventas, ...data} })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + '/ventas')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        //url.searchParams.append('cliente_id', this.state.cliente?._id)
        // if(this.state?.fechas?.length > 0){
        //     url.searchParams.append('fecha_inicial', this.state.fechas[0])
        //     url.searchParams.append('fecha_final', this.state.fechas[1])
        // }
        if(this.state?.ventas?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ventas?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            // let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            // if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            // let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            // if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }
        url.searchParams.append('csv', true)
        window.open(url, '_blank')

    }


    render() {

        return (
            <>

                <PageHeader
                    className="custom-page-header"
                    title={<Row>
                        <Col xs={20}>
                            <Title level={3}>Ventas</Title>
                        </Col>
                        <Col xs={4} className="flex-right">
                            <Button
                                type="dashed"
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<FilterOutlined />} />
                        </Col>
                    </Row>}
                >

                </PageHeader>

                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Ventas" }}
                        dataSource={this.state.ventas.data}
                        pagination={{
                            current: this.state.ventas.page,
                            pageSize: this.state.ventas.limit,
                            total: this.state.ventas.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            onChange: (page, limit) => this.getVentas({ page, limit })

                        }}
                        header={<Row className="component-list-header width-100 pr-1 pl-1">
                            <Col span={7} className="center">
                                <Text strong>Cliente</Text>
                            </Col>

                            <Col span={5} className="center">
                                <Text strong>Total</Text>
                            </Col>
                            <Col span={5} className="center">
                                <Text strong>Fecha</Text>
                            </Col>
                            <Col span={5} className="center">
                                <Text strong>Estatus</Text>
                            </Col>
                            <Col span={2} className="center">
                                <Text strong>Acciones</Text>
                            </Col>

                        </Row>
                        }
                        renderItem={item => (

                            <Collapse style={{marginBottom: 12}}>
                                <Panel header={
                                    <List.Item className="component-list-item">
                                        {/* <Card className="card-list"> */}
                                            <Row className="width-100 " gutter={[8, 8]}>
                                                <Col span={7} className="center">
                                                    <Text strong>{item?.cliente_id?.nombre ?? item?.datos_cliente?.nombre ?? "-"} {item?.cliente_id?.apellido_paterno ?? item?.datos_cliente?.apellido_paterno} {item?.cliente_id?.apellido_materno}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text className="text-gray">{item?.monto?.toMoney(true)} USD</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text className="text-gray">{moment(item.fecha).format('LLLL')}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text className="text-gray">{EstatusVenta(item.estatus)}</Text>
                                                </Col>
                                                <Col span={2} className="center">
                                                    <Space>
                                                        <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ modalVentas: true, venta_id: item._id })} />
                                                        <Button type="primary" icon={<AiOutlineEye />} onClick={() => this.setState({ modalVentasDetalle: true, venta_id: item._id })} />
                                                        <Popconfirm
                                                            title="¿Estás seguro de eliminar esta venta?"
                                                            onConfirm={() => {
                                                                axios.delete('/venta/' + item._id)
                                                                    .then(({ data }) => {
                                                                        message.success('Venta eliminada correctamente')
                                                                        this.getVentas()
                                                                    })
                                                                    .catch(res => {
                                                                        message.error('Ha ocurrido un error al eliminar la venta')
                                                                    })
                                                            }}
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button type="primary" danger icon={<IconDelete />} />
                                                        </Popconfirm>

                                                    </Space>
                                                </Col>
                                            </Row>
                                        {/* </Card> */}
                                    </List.Item>
                                } key="1">
                                    <Paragraph>Lista de Boletos</Paragraph>
                                    <Row>
                                        {item.boletos?.map(e => <Tag>{e}</Tag>)}
                                    </Row>
                                </Panel>

                            </Collapse>

                        )}
                    />
                </Content>

                <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVentas: true })} />

                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={() => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <ModalVentasDetalle
                    visible={this.state.modalVentasDetalle}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentasDetalle: false, venta_id: undefined })
                        if (flag === true) {
                            this.getVentas()
                        }
                    }}
                />

                <FiltrosDrawer
                    title="Filtrar Ventas"
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filters => this.getVentas({
                        page: 1,
                        filters
                    })}

                    csv="ventas"
                    downloadCSV={this.downloadCSV}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    sorteos={true}
                    clientes={true}
                    boletos={true}
                    estatus={true}
                />
            </>
        )
    }
}
