import React, { Component, useContext, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Menu, Typography, Button, Space, Drawer, Badge } from 'antd';
import { HashLink } from 'react-router-hash-link';
import { AiOutlineShoppingCart, AiOutlineMenu } from 'react-icons/ai';
import '../../Styles/Public/header.scss'
import CarritoDrawer from './Carrito';
import { Carrito } from '../../Hooks/Carrito';
import User from '../../Hooks/Logged'

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;

class HeaderPublic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDrawerCarrito: false,
            count: 0,
            clase: "transparent"
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.toggleCarrito != prevProps.toggleCarrito) {
            this.setState({ openDrawerCarrito: !this.state.openDrawerCarrito })
        }

        if (this.props.cart != prevProps.cart) {
            console.log("cambio en carrito wooo")
            this.setState({ count: this.props.cart?.length ?? 0 })
        }
    }

    handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > 0) {
            this.setState({ clase: "" });
        } else {
            this.setState({ clase: "transparent" });
        }
    };

    render() {
        return (
            <>
                <Header id={'header'} className={`header-landing ${this.state.clase}`}>
                    <Row className='w-100' align={'middle'} gutter={[16, 16]} justify={"space-between"}>
                        <Col xs={16} sm={8} md={6} xl={4} className="brand">
                            <Link className="width-100" to={'/'}>
                                <img
                                    className="width-100"
                                    src="/img/logo.svg"
                                    alt="Fuga pa´ Hermosillo"
                                    height={100}
                                // style={{ height: 40 }}
                                />
                            </Link>
                        </Col>
                        <Col xs={0} lg={12} className="links">
                            <Menu className="header-menu ml-2 width-100" theme='dark' mode="horizontal">
                                <Menu.Item key="1">
                                    <HashLink smooth to={'/#inicio'}>
                                        <Text className="text-white">INICIO</Text>
                                    </HashLink>

                                </Menu.Item>
                                <Menu.Item key="2">
                                    <HashLink smooth to='/#auto1'>
                                        <Text className="text-white">CONCURSA</Text>
                                    </HashLink>

                                </Menu.Item>
                                <Menu.Item key="4">
                                    <HashLink smooth to='/#dinamica'>
                                        <Text className="text-white">DINÁMICA</Text>
                                    </HashLink>
                                </Menu.Item>
                                <Menu.Item key="5" >
                                    <HashLink smooth to='/#ganadores'>
                                        <Text className="text-white">GANADORES</Text>
                                    </HashLink>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <HashLink smooth to='/#concursos'>
                                        <Text className="text-white">CONCURSOS</Text>
                                    </HashLink>

                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col xs={8} md={6} lg={6} className='actions'>
                            <Row>
                                <Col span={24} className="flex-right">

                                    {
                                        this.props.user ? (
                                            <Link className='cta' to={this.props.user?.cliente_id ? '/customer/compras' : '/admin/dashboard'} style={{ marginRight: '8px' }}>
                                                <Button
                                                    type="primary"
                                                >
                                                    Mi Cuenta
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link className='cta' to={"/login"} style={{ marginRight: '8px' }}>
                                                <Button
                                                    type="primary"
                                                >
                                                    ¡Participar ahora!
                                                </Button>
                                            </Link>
                                        )
                                    }
                                    {
                                        (this.props.user?.cliente_id || !this.props.user) && (
                                            <Badge count={this.state.count}>
                                                <Button onClick={() => { this.setState({ openDrawerCarrito: true }) }} ghost>
                                                    <AiOutlineShoppingCart size={20} />
                                                </Button>
                                            </Badge>
                                        )
                                    }
                                    <Button className='menu' onClick={() => this.setState({ openDrawerMenu: true })} ghost>
                                        <AiOutlineMenu size={20} />
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <CarritoDrawer open={this.state.openDrawerCarrito} sorteo={this.props.sorteoActual} onClose={() => { this.setState({ openDrawerCarrito: false }) }} />
                    <Drawer open={this.state.openDrawerMenu} onClose={() => { this.setState({ openDrawerMenu: false }) }}>
                        <Menu className="width-100" theme='light' mode="vertical">
                            <Menu.Item key="1">
                                <Link to={'/'}>
                                    <Text>INICIO</Text>
                                </Link>

                            </Menu.Item>
                            <Menu.Item key="2">
                                <HashLink smooth to='/#auto1'>
                                    <Text>CONCURSA</Text>
                                </HashLink>

                            </Menu.Item>
                            <Menu.Item key="3">
                                <HashLink smooth to='/#concursos'>
                                    <Text>CONCURSOS</Text>
                                </HashLink>

                            </Menu.Item>
                            <Menu.Item key="4">
                                <HashLink smooth to='/#dinamica'>
                                    <Text>DINÁMICA</Text>
                                </HashLink>
                            </Menu.Item>
                            <Menu.Item key="5" >
                                <HashLink smooth to='/#ganadores'>
                                    <Text>GANADORES</Text>
                                </HashLink>
                            </Menu.Item>
                            <Menu.Item>
                                {
                                    this.props.user ? (
                                        <Link className='cta' to={this.props.user?.cliente_id ? '/customer/compras' : '/admin/dashboard'} style={{ marginRight: '8px' }}>
                                            <Button
                                                type="primary"
                                            >
                                                Mi Cuenta
                                            </Button>
                                        </Link>
                                    ) : (
                                        <Link className='cta' to={"/login"} style={{ marginRight: '8px' }}>
                                            <Button
                                                type="primary"
                                            >
                                                ¡Participar ahora!
                                            </Button>
                                        </Link>
                                    )
                                }

                            </Menu.Item>
                        </Menu>
                    </Drawer>
                </Header>
            </>
        );
    }
}

export default function (props) {


    let cart = useContext(Carrito);
    let user = useContext(User)

    return <HeaderPublic cart={cart} navigate={useNavigate} user={user} {...props} />

}