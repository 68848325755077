import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, PageHeader, Layout, Space, message, Card, List } from 'antd'
import axios from 'axios';
import moment from 'moment'

//componentes
import Logged from "../../../../Hooks/Logged";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail, IconEye } from '../../../Widgets/Iconos';
import { AiOutlineEye } from 'react-icons/ai';

//modal
import ModalVentas from '../../Ventas/ModalVentas'
import ModalVentasDetalle from '../../Ventas/ModalDetalle'


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class VentasTab
 * @extends {Component}
 * @description Vista donde se listan todos las ventas
 */
export default class VentasTab extends Component {
    static contextType = Logged;


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            ventas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            venta_id: null
        }
    }

    componentDidMount() {
        this.getVentas()
    }

    componentDidUpdate(prevProps){
        if(prevProps.search != this.props.search){
            this.getVentas({page: 1, search: this.props.search})
        }
    }


    /**
    *
    * @memberof VentasTab
    * @method getVentas
    * @description Obtiene la informacion de las ventas
    */
    getVentas = async ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.props.search
    } = this.state.ventas) => {

        this.setState({ loading: true })

        axios.get('/ventas', {
            params: {
                limit,
                page,
                search,
                sorteo_id: this.props.sorteo_id
            }
        })
            .then(({ data }) => {
                this.setState({ ventas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderEstatus(estatus) {
        switch (estatus) {
            case 1:
                return <Text type="warning">Pendiente</Text>
            case 2:
                return <Text type="danger">Cancelado</Text>
            case 3:
                return <Text type="success">Completado</Text>
            default:
                return <Text type="warning">Pendiente</Text>
        }
    }



    render() {

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Ventas" }}
                    dataSource={this.state.ventas.data}
                    pagination={{
                        current: this.state.ventas.page,
                        pageSize: this.state.ventas.limit,
                        total: this.state.ventas.total,
                        hideOnSinglePage: false,
                        position: 'bottom',
                        onChange: (page, limit) => this.getVentas({ page, limit })

                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1" >
                        <Col span={7} className="center">
                            <Text strong>Cliente</Text>
                        </Col>

                        <Col span={5} className="center">
                            <Text strong>Total</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text strong>Estatus</Text>
                        </Col>
                        <Col span={2} className="center">
                            <Text strong>Acciones</Text>
                        </Col>

                    </Row>
                    }

                    renderItem={item => (
                        <List.Item className="component-list-item">

                            <Card className="card-list">
                                <Row className="width-100 " gutter={[8, 8]}>

                                    <Col xs={12} md={7} className="center">
                                        <Text strong>{item?.cliente_id?.nombre ? <>
                                            {item?.cliente_id?.apellido_paterno} {item?.cliente_id?.apellido_materno}
                                        </> : <>
                                            {item?.datos_cliente?.nombre ? <>
                                                {item?.datos_cliente?.nombre} {item?.datos_cliente?.apellido_paterno}
                                            </> : <>N/A</>}
                                        </>}</Text>
                                        {/* <Text strong>{item?.cliente_id?.nombre} {item?.cliente_id?.apellido_paterno} {item?.cliente_id?.apellido_materno}</Text> */}
                                    </Col>

                                    <Col xs={12} md={5} className="center">
                                        <Text className="text-gray">{item?.monto?.toMoney(true)} USD</Text>
                                    </Col>
                                    <Col xs={12} md={5} className="center">
                                        <Text className="text-gray">{moment(item.fecha).format('LLLL')}</Text>
                                    </Col>
                                    <Col xs={12} md={5} className="center">
                                        <Text className="text-gray">{this.renderEstatus(item.estatus)}</Text>
                                    </Col>
                                    <Col xs={24} md={2} className="center">
                                        <Space>
                                            <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ modalVentas: true, venta_id: item._id })} />
                                            <Button type="primary" icon={<AiOutlineEye />} onClick={() => this.setState({ modalVentasDetalle: true, venta_id: item._id })} />
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />

                <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVentas: true })} />

                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={() => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <ModalVentasDetalle
                    visible={this.state.modalVentasDetalle}
                    venta_id={this.state.venta_id}
                    onClose={() => {
                        this.setState({ modalVentasDetalle: false, venta_id: undefined })

                    }}
                />
            </>
        )
    }
}
