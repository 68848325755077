import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, DatePicker, Button } from 'antd';
import { AiOutlineMinusCircle } from "react-icons/ai";
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
//componentes
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalVenta extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            sorteo_id: null,
            sorteos: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

            clientes: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            boletos: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
                search: '',
            },
            cantidad: 0,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getSorteos()
        this.getClientes()
        if (this.props.venta_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.props.venta_id)
            .then(({ data }) => {
                data.fecha = moment(data.fecha)
                this.setState({ cantidad: data.cantidad })
                this.formRef.current.setFieldsValue(data)
            }).catch(error => {
                message.error('Error al obtener el usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        if (values.cliente_id.value) values.cliente_id = values.cliente_id.value

        if (this.props.venta_id) {
            this.update(values)
        } else {
            this.add(values)
        }
    }

    /**
    * @method add
    * @description Añade un nuevo registro de venta
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/venta', {
            ...values
        }).then(response => {
            message.success('¡Venta creada!')
            this.props.onClose(true)
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al crear la venta')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method update
    * @description Añade un nuevo registro de usuario
    */
    update = (values) => {

        if(values.estatus === 2){
            Modal.confirm({
                title: 'Cancelar venta',
                icon: <ExclamationCircleOutlined />,
                content: '¿Está seguro de cancelar la venta? Una vez cancelada, los boletos volverán a estar disponibles y esta venta no podrá ser modificada.',
                okText: 'Si',
                cancelText: 'Cancelar',
                onOk: () => this.updateVenta(values)
            });
        }else{
            this.updateVenta(values)
        }
    }

    /**
    * @method updateVenta
    * @description Actualiza la informacion de una venta
    */
    updateVenta = (values) => {
        this.setState({ loading: true })
        axios.put('/venta/' + this.props.venta_id, {
            ...values,
        }).then(response => {
            message.success('¡Venta Actualizada!')
            this.props.onClose(true)
        }).catch(res => {
            console.log("error", res);
            message.error(res.response.data ?? 'Error al actualizar la venta')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getSorteos = async ({
        page = this.state.sorteos.page,
        limit = this.state.sorteos.limit,
        search = this.props.search
    } = this.state.sorteos) => {

        this.setState({ loading: true, sorteos: { ...this.state.sorteos, page, limit } })

        axios.get('/sorteos', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ sorteos: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     * @const getBoletos
     * @description Obitiene los boletos disponibles
     */
    getBoletos = ({
        page,
        limit,
        search,
    } = this.state.boletos) => {

        if(!this.state.sorteo_id){
            message.error("Seleccione un sorteo");
            return;  
        } 

        axios.get('/boletos', {
            params: {
                page,
                limit,
                search,
                sorteo_id: this.state.sorteo_id,
                disponibles: true
            },

        }).then(({ data }) => {
            this.setState({ boletos: data })
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los boletos')
        })
    }

    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    getSorteo = (sorteo_id) => {
        this.setState({ loading: true })
        axios.get('/sorteo/' + sorteo_id)
            .then(({ data }) => {

                let boletos = this.formRef.current.getFieldValue('boletos')?.length ?? 0
                this.formRef.current.setFieldsValue({
                    costo_boleto: data.costo_boleto,
                    monto: (data.costo_boleto * boletos).toMoney()
                })
                this.setState({
                    sorteo_id: sorteo_id
                },() => this.getBoletos()); 
                
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @methodOf  Roles
     * @method getClientes
     *
     * @description Obtiene las Roles
     * */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.props.search
    } = this.state.clientes) => {
        this.setState({ loading: true })
        axios.get('/clientes', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {

            this.setState({
                clientes: data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    onChangeHandler = (e) => {
        let boletos = this.formRef.current.getFieldValue('boletos')?.length ?? 0;
        let costo = this.formRef.current.getFieldValue('costo_boleto') ?? 0;

        this.formRef.current.setFieldsValue({
            monto: (boletos * costo).toMoney()
        })


    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-ventas"
                    name="form-ventas"
                    key="form-ventas"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    initialValues={
                        this.props.initialValues ? { ...this.props.initialValues } : {}
                    }
                    onValuesChange={this.onChangeHandler}
                >
                    <Row justify="center" className="center">

                        <Col span={18}>

                            <Form.Item
                                name="fecha"
                                label="Fecha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                                name="referencia_num"
                                label="Referencia Numerica"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la referencia numerica',
                                    },
                                ]}
                            >
                                <InputNumber controls={false} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item label="Cliente" name="cliente_id" rules={[
                                {
                                    required: true,
                                    message: 'Seleccione un cliente',
                                },
                            ]}>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getClientes({ search, page: 1 })}
                                >
                                    {this.state.clientes.data.map(cliente =>
                                        <Option key={cliente._id} value={cliente._id}>
                                            {cliente.nombre} {cliente.apellido_paterno}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="sorteo_id"
                                label="Sorteo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el sorteo',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getSorteos({ search, page: 1 })}
                                    onSelect={(sorteo_id) => this.getSorteo(sorteo_id)}
                                >
                                    {this.state.sorteos.data.map(sorteo => <Option key={sorteo._id} value={sorteo._id} rol={sorteo}>{sorteo.nombre}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="estatus"
                                label="Estatus de la venta"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione un estatus',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    
                                    filterOption={false}
                                    
                                >
                                    <Option key={0} value={0} >No se completo la transacción</Option>
                                    <Option key={1} value={1} >Pendiente</Option>
                                    { this.props.venta_id ? <Option key={2} value={2} >Cancelado</Option> : null }
                                    <Option key={3} value={3} >Completado</Option>
                                    <Option key={4} value={4} >Por Confirmar</Option>
                                    
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="costo_boleto"
                                label="Costo del boleto"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el costo del boleto',
                                    },
                                ]}
                            >
                                <InputNumber
                                    controls={false}
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>



                            <p>
                                Números seleccionados
                            </p>

                            <Form.List
                                name="boletos"
                            >
                                {
                                    (fields, { add, remove }, { errors }) => (
                                        <Form.Item>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        //validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Seleccione un numero.",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Select
                                                            allowClear
                                                            showSearch
                                                            filterOption={false}
                                                            onSearch={search => this.getBoletos({ search, page: 1 })}
                                                            style={{width:"90%"}}
                                                            placeholder="Seleccione un número"
                                                        >
                                                            {this.state.boletos.data.map(boleto => <Option key={boleto.numero} value={boleto.numero}>{boleto.numero}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <AiOutlineMinusCircle
                                                            className="ml-1"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Form.Item>

                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Agregar Boleto
                                                </Button>


                                            </Form.Item>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>

                                    )
                                }

                            </Form.List>


                            <Form.Item
                                name="monto"
                                label="Monto"

                                rules={[

                                    {
                                        required: true,
                                        message: 'Ingrese el monto',
                                    },
                                ]}
                            >
                                <InputNumber
                                    disabled={true}
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={venta_id ? "Editar Venta" : "Crear Venta"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-ventas', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVenta  {...props} />
    </Modal>

}