import React, { Component } from "react";

import { CloseCircleOutlined } from '@ant-design/icons';

export default class TagBoleto extends Component {

    render() {
        return <div className="tag-boleto">
            <svg {...this.props} viewBox="0 0 693 294" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 96H409.5V98H410.5V108H409.5V118H410.5V128H409.5V138H410.5V148H409.5V158H410.5V168H409.5V178H410.5V188H409.5V198H410.5V208H409.5V218H410.5V228H409.5V238H410.5V248H409.5V258H410.5V271.019C407.309 271.262 404.762 273.809 404.519 277H14C6.26802 277 0 270.732 0 263V96Z" fill="#D9D9D9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M410.5 108V98H412.5V96H661.5V263C661.5 270.732 655.232 277 647.5 277H417.481C417.226 273.644 414.422 271 411 271C410.832 271 410.665 271.006 410.5 271.019V258H412.5V248H410.5V238H412.5V228H410.5V218H412.5V208H410.5V198H412.5V188H410.5V178H412.5V168H410.5V158H412.5V148H410.5V138H412.5V128H410.5V118H412.5V108H410.5Z" fill="#D9D9D9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M411 38C414.59 38 417.5 35.0899 417.5 31.5C417.5 31.3318 417.494 31.165 417.481 31H524.5V96H412.5V88H409.5V96H0V45C0 37.268 6.26801 31 14 31H404.519C404.506 31.165 404.5 31.3318 404.5 31.5C404.5 35.0899 407.41 38 411 38ZM412.5 58V48H409.5V58H412.5ZM409.5 78V68H412.5V78H409.5Z" fill="#E84C3D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M411 38C414.59 38 417.5 35.0899 417.5 31.5C417.5 31.3318 417.494 31.165 417.481 31H647.5C655.232 31 661.5 37.268 661.5 45V96H412.5V88H410.5V78H412.5V68H410.5V58H412.5V48H410.5V37.9811C410.665 37.9936 410.832 38 411 38Z" fill="#E84C3D"/>
                <circle cx="653" cy="40" r="40" fill="#DA1111"/>
                <line x1="636.359" y1="21.5325" x2="673.047" y2="55.5029" stroke="white" stroke-width="4"/>
                <line x1="635.662" y1="54.9703" x2="672.819" y2="21.5137" stroke="white" stroke-width="4"/>
                <text x="40" y="210" fontSize="100px" fontFamily="Poppins" fontWeight="700" fill="#E84C3D"> Boleto: </text>
                <text x="540" y="200" textAnchor="middle" fontSize="70px" fontFamily="Poppins" fontWeight="700" fill="#E84C3D">{this.props.numero ?? 0}</text>
            </svg>
        </div>
    }
}