import React from "react";
import { Row, Col, Button, Image, Layout, Typography, Card } from "antd";
import { Link } from "react-router-dom";
import { FaSquareFull } from "react-icons/fa";
import axios from "axios";

const { Content } = Layout;
const { Text } = Typography;

export default class Recientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sorteos: [],
        };
    }

    componentDidMount() {
        axios
            .get("/recientes", {
                params: {
                    tipo: 1
                }
            })
            .then(({ data }) => {
                this.setState({ sorteos: data });
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    render() {
        const { sorteos } = this.state;
        return (
            <Content>

                <Row justify={"center"} align="middle">
                    <FaSquareFull color='#40DDB6' className='mr-1' />
                    <Text className='mensaje2'>
                        PROMOCIONES
                    </Text>
                </Row>
                <Row justify={"center"}>
                    <Text className='titulo2'>
                        ¡Conoce nuestros concursos más recientes!
                    </Text>
                </Row>
                <Row className='m-3' gutter={[50, 50]}>

                    {sorteos.map((sorteo) => <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>



                        <Row className="card-recientes">
                            {
                                sorteo.disponibles <= 0 ? <div className="banner center">
                                    <Text className="banner-contenido">
                                        SOLD OUT
                                    </Text>
                                </div> : null
                            }


                            <Link to={`/evento/${sorteo.url}`} reloadDocument={true}>
                                <Card className="card-contenido" hoverable cover={<img src={sorteo.banner ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : "/img/recientes-1.png"} width={"100%"} style={{ objectFit: "cover" }} height={300} alt="Banner" />}>


                                    <Row className='mt-2'>
                                        <Text className='evento-titulo'>
                                            {sorteo.nombre}
                                        </Text>
                                    </Row>
                                    <Row>
                                        <Text className='evento-descripcion mt-1'>
                                            {sorteo.descripcion}
                                        </Text>
                                    </Row>
                                    <Row className='mt-2' justify={"end"}>
                                        <Button className='boton-evento' disabled={sorteo.disponibles <= 0}>
                                            ¡Participar ahora!
                                        </Button>

                                    </Row>
                                </Card>
                            </Link>
                        </Row>

                    </Col>)}



                </Row>
            </Content>

        );
    }
}