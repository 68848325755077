import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, Typography, DatePicker, Button, Divider, Upload } from 'antd';
import { AiOutlineMinusCircle } from "react-icons/ai";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Decimal from 'decimal.js';
//componentes
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
const { Option } = Select;
const { Text } = Typography


/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalVenta extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            venta: {},
            fileList: []

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.venta_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.props.venta_id, { params: { populate: true } })
            .then(({ data }) => {
                console.log('data', data)
                this.setState({ venta: data })
                if (data.comprobante)
                    this.setState({
                        fileList: [
                            {
                                uid: data?.comprobante?.file,
                                name: data?.comprobante?.name,
                                status: 'done',
                                fromDB: true,
                                url: axios.defaults.baseURL + "/venta/" + this.props.venta_id + "?comprobante=" + data?.comprobante?.file + "&Authorization=" + sessionStorage.getItem("token")
                            }
                        ]
                    })
                // console.log('this.refForm', this.refForm);
            }).catch(error => {
                message.error('Error al obtener la venta')
            }).finally(() => this.setState({ loading: false }))
    }


    render() {


        console.log('t', this.state.fileList);

        return (
            <Spin spinning={this.state.loading}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Row className="info-title">
                            Correo
                        </Row>
                        <Row>
                            <Text ellipsis className="info-data"> {this.state.venta?.cliente_id?.email} </Text>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row className="info-title">
                            Nombre
                        </Row>
                        <Row >
                            <Text ellipsis className="info-data">
                                {this.state.venta?.cliente_id?.nombre} {this.state.venta?.cliente_id?.apellido_paterno} {this.state.venta?.cliente_id?.apellido_materno}
                            </Text>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row className="info-title">
                            Ciudad
                        </Row>
                        <Row className="info-data">
                            {this.state.venta?.cliente_id?.ciudad ?? "-"}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row className="info-title">
                            Teléfono
                        </Row>
                        <Row className="info-data">
                            {this.state.venta?.cliente_id?.telefono ?? "-"}
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Text className="divider-title">
                        Boletos Adquiridos
                    </Text>
                </Row>
                
                <Row>
                    <Col span={12}>
                        Número de Boleto
                    </Col>
                    <Col span={12} className="right-align">
                        Precio
                    </Col>
                </Row>
                <Divider className="divider-modal" />
                {
                    this.state.venta?.boletos?.map((boleto, index) => {
                        return <>
                            <Row key={index}>
                                <Col span={12}>
                                    {boleto}
                                </Col>
                                <Col span={12} className="right-align">
                                    $ {this.state.venta?.costo_boleto?.toMoney(true)} USD
                                </Col>
                            </Row>
                            <Divider className="divider-modal" />
                        </>
                    })
                }
                <Row className="mt-2">
                    <Col span={12} className="strong">
                        Total
                    </Col>
                    <Col span={12} className="right-align">
                        <Text strong>$ {this.state.venta?.monto?.toMoney(true)} USD</Text>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col span={24} className="strong">

                        <Upload
                            fileList={this.state.fileList}
                            name='comprobante'
                            action={axios.defaults.baseURL + '/customer/venta/' + this.props.venta_id}
                            headers={{
                                authorization: sessionStorage.getItem('token')
                            }}
                            method="PUT"
                            onSuccess={ () => this.get()}
                            onChange={(info, a,b) => {
                                if (info.file.status === 'done') {
                                    message.success(`Comprobante subido exitosamente`);
                                }
                                if (info.file.status === 'error') {
                                    message.error(`No se pudo subir el comprobante`);
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>
                                Subir Comprobante de Pago
                            </Button>
                        </Upload>

                    </Col>
                </Row>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, venta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title="Detalle de venta"
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="OK"
        cancelButtonProps={{ style: { display: 'none' } }}
        width={700}
        onOk={onClose}
    >
        <ModalVenta  {...props} />
    </Modal>

}