import React, { Component } from "react";
import { Row, Col, Typography, Spin, PageHeader, Layout, message, Card, Space, Tabs, Breadcrumb, Tooltip } from "antd";
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom";
import { QuestionCircleOutlined } from '@ant-design/icons';

//Componentes
import DrawerRifa from "./DrawerRifa";
import VentasTab from './Tabs/VentasTab';
import { EstatusRifa } from '../../Utils';
import PremiosTab from './Tabs/PremiosTab';
import ClientesTab from './Tabs/ClientesTab';
import GanadoresTab from './Tabs/GanadoresTab';
import { CardRifa } from "../../Widgets/Cards";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ButtonDelete, ButtonView, ButtonEdit, } from "../../Widgets/Buttons";

const { Content } = Layout;
const { Paragraph, Title, Text } = Typography;
const moment = require('moment');

/**
 *
 *
 * @export
 * @class RifaDetalle
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class RifaDetalle extends Component {

    /**
     *Creates an instance of proyectos.
     * @param {*} props
     * @memberof RifaDetalle
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            sorteo: {
                cantidad_boletos: 0,
                vendidos: 0,
                costo_boleto: 0

            },
            proyecto_id: null,
        };
    }

    refPremioTab = React.createRef()

    componentDidMount() {

        if (this.props.params.sorteo_id)
            this.getSorteo()

    }


    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    getSorteo = () => {
        this.setState({ loading: true })
        axios.get('/sorteo/' + this.props.params.sorteo_id, {
            params: {
                ventas: true
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ sorteo: data })
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }


    /**
   * @method deleteSorteo
   * @description Elimina un registro de rifas
   */
    deleteSorteo = (sorteo_id) => {
        this.setState({ loading: true })
        axios.delete('/sorteo/' + sorteo_id, {})
            .then(({ data }) => {
                message.success("Eliminado con exito")
                this.props.navigate("/admin/rifas")
            }).catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al eliminar el sorteo")

            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        let { sorteo } = this.state

        return (
            <>

                <PageHeader
                    className="site-page-header custom-page-header"
                    title={<Breadcrumb>
                        <Breadcrumb.Item>
                            <Text underline className="hover" onClick={() => this.props.navigate('/admin/rifas')}>Rifas</Text>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{sorteo.nombre}</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <Content className="admin-content content-bg pd-1">
                    <Row>

                        <Col span={24}>
                            <Spin spinning={this.state.loading}>
                                <Card bordered>
                                    <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                        <Col span={16} className="flex-column-left">
                                            <Title level={4} className="m-0">{sorteo.nombre}</Title>
                                            <Text type="secondary">/{sorteo.url}</Text>
                                        </Col>
                                        <Col span={8} className="flex-right">
                                            <Space>
                                                <ButtonEdit
                                                    onClick={() => this.setState({ modal_visible: true })}
                                                />
                                                <ButtonDelete
                                                    onConfirm={() => this.deleteSorteo(sorteo._id)}
                                                />
                                                {EstatusRifa(sorteo.estatus)}
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12} xl={12}>
                                            <Paragraph strong>Descripción</Paragraph>
                                            <Paragraph style={{ fontSize: 14, textAlign: "justify" }}>{sorteo.descripcion}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph strong>Fecha de Inicio</Paragraph>
                                            <Paragraph style={{ fontSize: 16 }}>{moment(sorteo.fecha_inicio).format("dddd D [de] MMMM [del] YYYY")}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph strong>Fecha de Finalización</Paragraph>
                                            <Paragraph style={{ fontSize: 16 }}>{moment(sorteo.fecha_finalizacion).format("dddd D [de] MMMM [del] YYYY")}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Spin>
                        </Col>
                        <Col span={24}>
                            <Spin spinning={this.state.loading}>
                                <Card bordered className='mt-1'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={12} lg={12} xl={5} className="flex-column">
                                            <Paragraph strong>Boletos Vendidos </Paragraph>
                                            <Paragraph>{sorteo.boletos_vendidos?.toMoney(true, false)}</Paragraph>
                                        </Col>

                                        <Col xs={12} lg={12} xl={5} className="flex-column">
                                            <Paragraph strong>Boletos por Vender</Paragraph>
                                            <Paragraph>{sorteo.boletos_no_vendidos?.toMoney(true, false)}</Paragraph>
                                        </Col>

                                        <Col xs={12} lg={12} xl={4} className="flex-column">
                                            <Paragraph strong>Costo Boleto</Paragraph>
                                            <Paragraph>$ {sorteo.costo_boleto?.toMoney(true)} USD</Paragraph>
                                        </Col>

                                        <Col xs={12} lg={12} xl={5} className="flex-column">
                                            <Paragraph strong>Monto Vendido <Tooltip placement="topRight" title={"Solo se toman en cuenta las ventas completadas"}> <QuestionCircleOutlined /> </Tooltip></Paragraph>
                                            <Paragraph>$ {(sorteo.monto_total ?? 0)?.toMoney(true)} USD</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={24} xl={5} className="flex-column">
                                            <Paragraph strong>Total Pendiete</Paragraph>
                                            <Paragraph>$ {((sorteo.cantidad_boletos * sorteo.costo_boleto) - (sorteo.monto_total ?? 0)).toMoney(true)} USD</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Spin>
                        </Col>
                        <Col span={24}>
                            <Tabs>
                                <Tabs.TabPane tab="Ventas Realizadas" key="1">
                                    <VentasTab sorteo_id={this.props.params.sorteo_id} {...this.props}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Clientes" key="2">
                                    <ClientesTab sorteo_id={this.props.params.sorteo_id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Premios" key="3">
                                    <PremiosTab
                                        sorteo_id={this.props.params.sorteo_id}
                                        ref={this.refPremioTab}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Ganadores" key="4">
                                    <GanadoresTab
                                        sorteo_id={this.props.params.sorteo_id}
                                        getSorteo={this.getSorteo}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Content>
                <DrawerRifa
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false })
                        if (flag === true) {
                            this.getSorteo()
                            console.clear()
                            console.log("this.refPremioTab.current", this.refPremioTab.current);
                            if (this.refPremioTab.current) {
                                this.refPremioTab.current.getSorteo()
                            }
                        }
                    }}
                    sorteo_id={sorteo._id}
                />
            </>
        );
    }
}

export default function (props) {

    return <RifaDetalle {...props} navigate={useNavigate()} params={useParams()} />
}