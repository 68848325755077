import React, { Component } from "react";
import { Row, Col, Spin, PageHeader, Layout, message, Pagination, Typography } from "antd";
import axios from "axios"
import { useNavigate } from "react-router-dom";

//Componentes
import DrawerRifa from "./DrawerRifa";
import DrawerImagenes from "./DrawerImagenes";
import { CardRifa } from "../../Widgets/Cards";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

const { Content } = Layout;
const { Title } = Typography;


/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class Proyectos extends Component {

    /**
     *Creates an instance of proyectos.
     * @param {*} props
     * @memberof Proyectos
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            rifas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyecto_id: null,
        };
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getSorteos()
    }

    /**
     *
     *
     * @memberof Roles
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
     componentDidUpdate(prevProps) {

            console.log("this.props.search", this.props.search);
        if (this.props.search !== prevProps.search) {
            this.getSorteos();
        }
    } 

    /**
    * @method getSorteos
    * @description obtiene un registro de rifas
    */
    getSorteos = ({
        page = this.state.rifas.page,
        limit = this.state.rifas.limit,
        search = this.props.search,
    } = this.state.rifas) => {
        this.setState({loading: true})
        axios.get('/sorteos',{
            params:{
                page, 
                limit,
                search
            }
        })
        .then(({data}) => {
            console.log("data", data);
            this.setState({rifas: data})
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las rifas")

        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @method deleteSorteo
    * @description Elimina un registro de rifas
    */
    deleteSorteo = (sorteo_id) => {
        this.setState({loading: true})
        axios.delete('/sorteo/'+sorteo_id,{})
        .then(({data}) => {
            message.success("Eliminado con exito")
            this.getSorteos()
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al eliminar el sorteo")

        }).finally(()=>this.setState({loading: false}))
    }

    

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={<Row>
                            <Col xs={20}>
                                <Title level={3}>Concursos y Sorteos</Title>
                            </Col>
                        </Row>}
                    />
                    <Content className="admin-content content-bg pd-1">
                    	<Row gutter={[16,16]}>
	                        
	                        {this.state.rifas?.data?.map(item => <Col xs={24} md={12} xl={8} xxl={6} className="grid">
	                            <CardRifa 
                                    {...item} 
                                    onView={()=>this.props.navigate(`/admin/rifas/detalle/${item._id}`)}
                                    onEdit={()=>this.setState({drawer_open: true, sorteo_id: item._id})}
                                    onDelete={()=>this.deleteSorteo(item._id)}
                                    //onImg={()=>this.setState({drawer_imagenes: true, sorteo_id: item._id})}
                                />
	                        </Col>)}
			                  
			            </Row>
                        <Pagination 
                            className="mt-1"
                            defaultCurrent={1} 
                            current={this.state.rifas.page}
                            limit={this.state.rifas.limit}
                            total={this.state.rifas.total}
                            onChange={(page)=>this.getSorteos({page})}
                        />
                    </Content>
                </Spin>
                <FloatingButton onClick={()=>this.setState({drawer_open: true})}/>
                <DrawerRifa
                    open={this.state.drawer_open}
                    onClose={(flag)=>{
                        this.setState({ drawer_open: false, sorteo_id: undefined })
                        if(flag === true)
                            this.getSorteos()
                    }}
                    sorteo_id={this.state.sorteo_id}
                />
                <DrawerImagenes
                    open={this.state.drawer_imagenes}
                    onClose={(flag)=>{
                        this.setState({ drawer_imagenes: false, sorteo_id: undefined })
                        if(flag === true)
                            this.getSorteos()
                    }}
                    sorteo_id={this.state.sorteo_id}
                />
            </>
        );
    }
}

export default function (props) {

    return <Proyectos {...props}  navigate={useNavigate()}/>
}