import React, { Component, useContext } from "react";
import { Row, Tabs, Col, Card, message, Popconfirm, Menu, Input } from "antd";
import Decimal from "decimal.js";
import axios from "axios";
import { Button } from "antd/lib/radio";
import { Carrito, SetCarrito } from "../../Hooks/Carrito";
import TagBoleto from "./TagBoleto";
import { useCookies } from "react-cookie";
import { Content } from "antd/lib/layout/layout";
import ModalGuest from './ModalGuest'
import Guest from "../../Hooks/Guest";
import User from "../../Hooks/Logged";
import ModalRuleta from "./ModalRuleta";

class Boletos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            boletosVendidos: [],
            boletosSeleccionados: {

            },
            total: 0,
            sorteo_id: this.props.sorteo_id,
            millares: 1,
            centenas: 1,
            costo: 100,
            optionMillares: null,
            busqueda: null

        };
    }

    boletosRef = React.createRef()

  

    componentDidMount() {
        // console.log("props", this.props)
        this.setState({ total: this.props.total })
        this.setState({ costo: this.props.costo })
        this.setState({ sorteo_id: this.props.sorteo_id })
        if (this.props.cart) {
            console.log("cart", this.props.cart)
            let boletosSeleccionados = {}
            this.props.cart.forEach(item => {
                boletosSeleccionados[item.numero] = true
            })
            this.setState({ boletosSeleccionados: boletosSeleccionados })
        }
        this.getDisponibles();
        // if (this.props.total > 1000)

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.total != this.props.total || prevProps.costo != this.props.costo || prevProps.sorteo_id != this.props.sorteo_id) {
            this.setState({ total: this.props.total, costo: this.props.costo, sorteo_id: this.props.sorteo_id })
            this.getDisponibles()
            this.setState({ optionMillares: 0, optionCentenas: 0 })
            // console.log('this.props.total', this.props.total);


        }
        // let x = 0
        // console.log("prevProps.cart", prevProps.cart)
        // console.log("this.props.cart", this.props.cart)
        if (prevProps.cart !== this.props.cart) {
            if (this.props.cart) {
                console.log("cart", this.props.cart)
                let boletosSeleccionados = {}
                this.props.cart.forEach(item => {
                    boletosSeleccionados[item.numero] = true
                })
                this.setState({ boletosSeleccionados: boletosSeleccionados })
            }

            this.getDisponibles()
        }
    }


    getDisponibles = (
        min = 0,
        max = 100,
        busqueda = this.state.busqueda
    ) => {
        if (this.props.sorteo_id == null || this.props.sorteo_id == 'undefined') return null
        console.log("Get disponibles", min, max)
        console.log("XXXXX", busqueda)
        axios.get('/info/' + this.props.sorteo_id + '/boletos', {
            params: {
                min: min ?? 1,
                max: max ?? 100,
                busqueda
            }
        })
            .then(({ data }) => {
                // console.log("data", data);
                this.setState({
                    boletosVendidos: [...data],
                    
                })
            })
            .catch(error => {
                // console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }

    addToCart = (numero, costo, sorteo_id) => {

        console.log("addToCart", numero, costo, sorteo_id,"GUEST", this.props.guest, "USER", this.props.user)

        if (this.props.guest == null && this.props.user == null) {

            if (this.props.user) {
                
            } else {
                this.setState({ modalGuestVisible: true })
                return;
            }

			// this.setState({ modalGuestVisible: true })
			// return;
		}


        const cart = this.props.cart ?? [];
        const index = cart.findIndex(item => item.numero == numero);

        if (index == -1) {
            cart.push({ numero: numero, costo: costo, sorteo_id: sorteo_id })
            this.setState({ boletosSeleccionados: { ...this.state.boletosSeleccionados, [numero]: true } })
            message.info(`Boleto no. ${numero} agregado al carrito`)
        } else {
            cart.splice(index, 1)
            let nuevosBoletos = this.state.boletosSeleccionados
            delete nuevosBoletos[numero]
            this.setState({ boletosSeleccionados: nuevosBoletos })
            message.info("Boleto eliminado del carrito")
        }

        axios.post('/compra', {
            venta_id: this.props.cookies.cart,
            boletos: this.props.cart.map(item => item.numero),
            sorteo_id: sorteo_id,
            cantidad: this.props.cart.length,
            costo_boleto: costo,
            monto: this.props.cart.length * costo,
            datos_cliente: this.props.guest
        }).then(({ data }) => {
            console.log(data)
            this.props.setCookie("cart", data._id)
        }).catch((error) => {
            console.log(error)
            cart.splice(index, 1)
            let nuevosBoletos = this.state.boletosSeleccionados
            delete nuevosBoletos[numero]
            this.setState({ boletosSeleccionados: nuevosBoletos })
            message.error(error.response.data ?? "Ocurrio un error al agregar el boleto al carrito")
        })



        console.log("las cookies", this.props.cookies)
        this.setState({ loading: true })

        this.props.setCart(cart)
    }

    renderBoletos = () => {
        const { boletosVendidos } = this.state
        const items = new Array(Decimal(this.state.total)
            .div(1000)
            .ceil()
            .toNumber())
            .fill(null)
            .map((_, i) => {
                return ({
                    label: `${i == 0 ? '0' : `${i}000`} - ${(this.state.total < (i + 1) * 1000) ? String(this.state.total) : `${i + 1}000`}`,
                    key: String(i),
                })
            })

        let busquedaAv = (!this.state.busqueda || (this.state.busqueda == ''))

        return <>
            <Row style={{ width: "100%", justifyContent: "center" }}>
                <Input onChange={event => {
                    this.setState({ busqueda: event.target.value }, () => this.getDisponibles())
                    this.getDisponibles()
                }} placeholder="Buscar un boleto..." style={{ maxWidth: 300 }} />
            </Row>
            {(busquedaAv) && <Menu
                className="menu-boletos"
                // onClick={onClick}
                align="center"
                selectedKeys={[String(this.state.optionMillares)]}
                onSelect={({ key }) => {
                    let max = (Number(key) + 1) * 1000
                    let min = Number(key) * 1000

                    // console.log(' - -  - -  - -  - -  - -  - -  - -  - - ')
                    // console.log(' - -  - -  - -  - -  - -  - -  - -  - - ')
                    // console.log('max', max)
                    // console.log('min', min)
                    // console.log('key', key)
                    this.getDisponibles(min, min + 100)
                    this.setState({
                        optionMillares: Number(key),
                        optionCentenas: 0
                    })
                }}
                mode="horizontal" items={items} />}

            {(busquedaAv) && (typeof this.state.optionMillares == "number") && <Menu
                className="menu-boletos"
                align="center"
                selectedKeys={[String(this.state.optionCentenas)]}
                items={
                    new Array((this.state.total >= (Number(this.state.optionMillares) + 1) * 1000) ? 10 : Decimal(this.state.total).mod(1000).div(100).ceil().toNumber()).fill(null).map((item, index) => ({
                        label: `${this.state.optionMillares * 1000 + index * 100} - ${(this.state.optionMillares * 1000 + (index + 1) * 100) >= this.state.total ? String(this.state.total) : `${this.state.optionMillares * 1000 + (index + 1) * 100}`}`,
                        key: String(index),
                    }))
                }
                onSelect={({ key }) => {
                    let max = (Number(this.state.optionMillares) * 1000) + (Number(key) * 100)
                    let min = (Number(this.state.optionMillares) * 1000) + ((Number(key) + 1) * 100)

                    this.getDisponibles(max, min)
                    this.setState({ optionCentenas: Number(key) })
                }}
                mode="horizontal" />}
            <Row className="mt-1" align={"middle"} justify={"center"} gutter={[16, 16]}>
                {
                    this.state.boletosVendidos.map(e => {
                        let currentBoleto = e.numero
                        let seleccionado = this.state.boletosSeleccionados[(currentBoleto)]
                        let vendido = e.vendido
                        return <Col sxs={8} sm={4} md={4} lg={2} xl={2} xxl={2} className="center">
                            <Button
                                className={`boton-boleto ${vendido ? 'vendido' : seleccionado ? 'seleccionado' : ' '} `}
                                onClick={() =>  this.addToCart((currentBoleto), this.state.costo, this.state.sorteo_id)}
                                disabled={vendido}
                            >
                                <Row align={"middle"} justify={"center"} style={{ height: "100%", color: "white" }}>
                                    {currentBoleto}
                                </Row>
                            </Button>
                        </Col>
                    })
                }
                {/* 
           
                {
                    busquedaAv ? new Array((this.state.total) >= (
                        this.state.optionMillares * 1000 + this.state.optionCentenas * 100 + 100
                    ) ? 100 : Decimal(
                        this.state.total
                    ).mod(100).ceil().toNumber()).fill(null).map((_, i) => {
                        let currentBoleto = this.state.optionMillares * 1000 + this.state.optionCentenas * 100 + i + 1

                        return <Col sxs={8} sm={4} md={4} lg={2} xl={2} xxl={2} className="center">
                            <Button className={
                                `boton-boleto ${boletosVendidos[(currentBoleto)] ? 'vendido' : this.state.boletosSeleccionados[(currentBoleto)] ? 'seleccionado' : ' '} `
                            }
                                onClick={() => !this.props.guest && !this.props.user ? this.setState({ modalGuestVisible: true }) : this.addToCart((currentBoleto), this.state.costo, this.state.sorteo_id)}
                                disabled={boletosVendidos[currentBoleto] !== undefined ? boletosVendidos[currentBoleto] : true}>
                                <Row align={"middle"} justify={"center"} style={{ height: "100%", color: "white" }}>
                                    {currentBoleto}
                                </Row>
                            </Button>
                        </Col>
                    }) : Object.entries(boletosVendidos).slice(0,100).map(e => {
                        const [currentBoleto] = e
                        // console.log('e', e)
                        return <Col sxs={8} sm={4} md={4} lg={2} xl={2} xxl={2} className="center">
                        <Button className={
                            `boton-boleto ${boletosVendidos[(currentBoleto)] ? 'vendido' : this.state.boletosSeleccionados[(currentBoleto)] ? 'seleccionado' : ' '} `
                        }
                            onClick={() => !this.props.guest && !this.props.user ? this.setState({ modalGuestVisible: true }) : this.addToCart((currentBoleto), this.state.costo, this.state.sorteo_id)}
                            disabled={boletosVendidos[currentBoleto] !== undefined ? boletosVendidos[currentBoleto] : true}>
                            <Row align={"middle"} justify={"center"} style={{ height: "100%", color: "white" }}>
                                {currentBoleto}
                            </Row>
                        </Button>
                    </Col>
                    })
                }
            */}
            </Row>

            <ModalGuest
                visible={this.state.modalGuestVisible}
                onSuccess={() => {
                    message.success("Gracias, puede continuar con la compra")
                    this.setState({ modalGuestVisible: false })
                }}
                onClose={() => {
                    this.setState({ modalGuestVisible: false })
                }}

            />

        </>

    }


    render() {
        return <Content id="boletos">
            {
                this.renderBoletos()
            }

            {/* 
            <Tabs
                defaultActiveKey="0"
                className="width-100"
                centered={true}
                onChange={(key) => { this.getDisponibles(Number(key) * 1000, (Number(key) + 1) * 1000) }}
                items={new Array(Decimal(this.state.total).div(1000).ceil().toNumber()).fill(null).map((_, i) => {


                    const id = String(i);
                    return {
                        label: `${i == 0 ? '0' : `${i}000`} - ${(this.state.total < (i + 1) * 1000) ? String(this.state.total) : `${i + 1}000`}`,
                        key: id,
                        children: <Tabs
                            defaultActiveKey="0"
                            className="width-100"
                            centered={true}

                            items={new Array((this.state.total >= (id + 1) * 1000) ? 10 : Decimal(this.state.total).mod(1000).div(100).ceil().toNumber()).fill(null).map((_, i) => {
                                const childid = String(i);

                                return {
                                    label: `${id * 1000 + i * 100} - ${(id * 1000 + (i + 1) * 100) >= this.state.total ? String(this.state.total) : `${id * 1000 + (i + 1) * 100}`}`,
                                    key: childid,

                                    children: <Row align={"middle"} justify={"center"} gutter={[16, 16]}>
                                        {
                                            new Array((this.state.total) >= (id * 1000 + childid * 100 + 100) ? 100 : Decimal(this.state.total).mod(100).ceil().toNumber()).fill(null).map((_, i) => {
                                                return <Col sxs={8} sm={4} md={4} lg={2} xl={2} xxl={2} className="center">
                                                    <Button className={`boton-boleto ${this.state.boletosVendidos[(id * 1000 + childid * 100 + i + 1)] ? 'vendido' : this.state.boletosSeleccionados[(id * 1000 + childid * 100 + i + 1)] ? 'seleccionado' : ' '} `}
                                                        onClick={() => this.addToCart((id * 1000 + childid * 100 + i + 1), this.state.costo, this.state.sorteo_id)}
                                                        disabled={this.state.boletosVendidos[id * 1000 + childid * 100 + i + 1]}>
                                                        <Row align={"middle"} justify={"center"} style={{ height: "100%", color: "white" }}>{id * 1000 + childid * 100 + i + 1}</Row>
                                                    </Button>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                }
                            })
                            }
                        />
                    }
                })
                }

            /> */}
            <Row justify={"center"} gutter={[16, 16]} className="width-100 mt-2">

                {this.props?.cart?.map((obj) => {
                    return <Col xs={8} md={4}>
                        <Popconfirm
                            title="¿Estás seguro de eliminar este boleto?"
                            onConfirm={() => this.addToCart(obj.numero, this.state.costo, this.state.sorteo_id)}
                            okText="Si"
                            cancelText="No"
                            className=" pr-1"
                        >
                            <TagBoleto width="160" height="85" numero={obj.numero} />
                        </Popconfirm>

                    </Col>
                })}
            </Row>

        </Content>

    }
}

export default function Vista(props) {

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)
    let guest = useContext(Guest)
    let user = useContext(User)
    const [cookies, setCookie] = useCookies(['cart']);
    console.log('cookies')
    return <Boletos {...props} cart={cart} setCart={setCart} guest={guest} user={user} cookies={cookies} setCookie={setCookie} />

}